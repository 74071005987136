<template>
  <span>{{ myPrice }}</span>
</template>

<script>
import dinero from "dinero.js";
export default {
  name: "EventPrice",
  props: {
    price: {
      required: true,
      type: Number,
    },
    currency: {
      required: true,
      type: String,
    },
  },
  computed: {
    myPrice() {
      const price = dinero({ amount: this.price, currency: this.currency });
      price.setLocale("en-US");
      return price.toFormat("$0,0.00");
    },
  },
};
</script>

<style scoped></style>
