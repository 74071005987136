<template>
  <div>
    <span>{{ localTime }}</span>
    <span v-if="showOriginalTime">{{ originalTime }}</span>
  </div>
</template>

<script>
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "EventDate",
  props: {
    timestamp: {
      required: true,
      type: Number,
    },
    timezone: {
      required: true,
      type: String,
    },
    showOriginalTime: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    originalTime() {
      const dateString = dayjs.unix(this.timestamp).toLocalString();
      const da = dayjs.tz(dateString, this.timezone);
      return da.toString();
    },
    localTime() {
      return dayjs.unix(this.timestamp).format("llll").toString();
    },
  },
};
</script>

<style scoped></style>
